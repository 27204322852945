@font-face {
  font-family: 'Gilroy';
  src: url('https://shared-assets.tatio.io/fonts/Gilroy-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://shared-assets.tatio.io/fonts/Gilroy-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://shared-assets.tatio.io/fonts/Gilroy-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://shared-assets.tatio.io/fonts/Gilroy-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://shared-assets.tatio.io/fonts/Gilroy-Bold.otf');
  font-weight: 700;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.App {
  display: flex;
  height: 100vh;
}
:root {
  --primary-font: Gilroy, Arial, Helvetica, sans-serif;
  --menu-dark-blue: #081271;
  --standard-grey: #777777;
  --avatar-dark-blue: #00004e;
  --mobile-dark-blue: #00003e;
  --yellow: #ffc100;
  --light-purple-grey: #9dacd3;
  --purple-grey: #8080a7;
  --light-blue: #07e7f6;
  --lighter-blue: rgba(203, 224, 243, 0.2);
  --light-white: #f9f9f9;
  --dark-grey: rgba(119, 119, 119, 1);
  --grey: #cccccc;
  --very-light-grey: #fcfcfc;
  --gray: var(--grey);
  --grey-with-opacity: rgba(120, 120, 128, 0.2);
  --almost-black: #1a1a1a;
  --very-dark-grey: #414143;
  --e-grey: #eeeeee;
  --platform-pink-gradient: linear-gradient(
    294.82deg,
    #e40c99 143.54%,
    #ce14c2 168.09%,
    #bb1be8 194.12%,
    #b31ef7 207.51%
  );
  --blue-gradient: radial-gradient(40.57% 72.12% at 50% 50%, #01016e 0%, #010150 100%);
  --border-light-grey: #ecebeb;
  --opacity-dark-blue: rgba(0, 0, 78, 0.7);
  --purple-blue: #4413c6;
  /* The following colors are the brand colors, for backwards compitability some old names that been given to these colors will stay */
  /* Dupllicated colors: (--yellow, --tatio-pink) */
}

body {
  font-family: var(--primary-font);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --error-red: #ec1637;
  --white: #ffffff;
  --gray: #cccccc;
  --dark-gray: #777777;
  --very-dark-gray: #414143;
  --tatio-green: #07f698;
  --almost-black: #1a1a1a;
  --purple-grey: #8080a7;
  --orange: #ff5811;
  --blue: #2c38ad;
  --silver-sage: #c4c4c4;
  --platform-blue: #081271;
  --platform-yellow-gradient: linear-gradient(
    243.24deg,
    #ffc300 4.65%,
    #ffc100 29.9%,
    #ffb900 44.32%,
    #ffab00 55.86%,
    #ff9700 65.24%,
    #ff7e00 74.61%,
    #ff7600 76.78%
  );
  --platform-yellow-gradient-fallback: #ffc300;
  --platform-pink: #e40c99;
  --platform-pink-gradient: linear-gradient(
    294.82deg,
    #e40c99 143.54%,
    #ce14c2 168.09%,
    #bb1be8 194.12%,
    #b31ef7 207.51%
  );
  --platform-pink-hover: rgba(248, 9, 119, 0.25);
  --platform-pink-focus: rgba(248, 9, 119, 0.1);
  --gilroy-font: 'Gilroy', sans-serif;
}

button {
  cursor: pointer;
}

:root {
  --dark-blue: #00004f;
  --blue: #0065eb;
  --green: #009068;
  --tatio-green: #07f698;
  --light-grey: #f9f9f9;
  --frosted-sky: #f9fafe;
  --tatio-pink: #e20c98;
  --light-gray: var(--light-grey);
  --turquoise: #1e938a;
  --red: #ec1637;
  --very-dark-grey: #414143;
  --brand-purple: #5b32af;
  --brand-light-purple: #9933ff;
  --brand-pink: #e20c98;
  --brand-orange: #ff5811;
  --brand-yellow: #ffc100;
  --orange: #ff5811;
  --light-orange: #f60;
  --grey90: #e5e5e5; /* named like this because of it's hsl represntation hsl(0,0%,90%) */
  --gray90: var(--grey90);
  --platform-blue: #081271;
  --gray95: #f2f2f2;
  --gray-light: #0812710d;
  --lavender: #d0c8f7;
}
